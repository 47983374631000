import { HttpErrorResponse } from '@angular/common/http';
import { Component, HostListener, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { environment } from '@env/environment';
import { SidebarService } from '@services/sidebar.service';
import { SessionService } from '@store/session/session.service';
import { take } from 'rxjs';

@Component({
  selector: 'app-root',
  template: '<router-outlet></router-outlet>',
})
export class AppComponent implements OnInit {

	constructor(
    private readonly titleService: Title,
    public sidebarService: SidebarService,
    ) {}

	ngOnInit(): void {
    this.titleService.setTitle(environment.appName);
	}

  
  @HostListener('document:click', ['$event'])
  onDocumentClick(event: MouseEvent): void {
    if (this.sidebarService.active.value && window.matchMedia('(max-width: 1199px)').matches) {
      this.sidebarService.active.next(false);
    }
  }
}
