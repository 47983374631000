export const environment = {
    production: false,
      appName: 'Vapt Manager',
      home: '/painel',
      api: 'https://api-dev.vapt.mobi/api',
      // api: 'https://a724-187-19-130-126.ngrok-free.app/api',
      // api: 'http://127.0.0.1:8000/api',
      url: '',
  
      passwordRules: {
          minlength: 8,
          minLowercaseCharacterRule: 1,
          minUppercaseCharacterRule: 1,
          minDigitCharacterRule: 1,
          minSpecialCharacterRule: 1
      },
      firebaseConfig: {
        apiKey: "AIzaSyBSbevJuayL3f5vGFPweqknJCvjQLkrWFI",
        authDomain: "vapt---manager.firebaseapp.com",
        projectId: "vapt---manager",
        storageBucket: "vapt---manager.firebasestorage.app",
        messagingSenderId: "637652472981",
        appId: "1:637652472981:web:0b467a69a594d17470379c",
        measurementId: "G-6W7QRSSPE0",
        vapidKey: "BCKRvgCrFHxWJLd5UA6HkWAc4Zk_CEEG_CJZNn2TOAcDQNkjeJeRCVf3gmZ9xxaZyL0aKVj0mEjxff4KqWf3XL0",
      }
  };
  