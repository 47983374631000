import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@env/environment';
import { ApiResponse, ApiResponsePageable, DeleteApiResponse, PageControl } from '@model/application';
import { UserInfo } from '@model/user';
import { UserStatus } from '@shared/enums/UserStatus';
import { Utils } from '@shared/utils';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class UserService {

  constructor(
    private readonly _http: HttpClient
  ) { }

  public getUsers(pageControl: PageControl, filters?): Observable<ApiResponsePageable<UserInfo>> {
    const paginate = Utils.mountPageControl(pageControl);
    const filterParams = Utils.mountPageControl(filters);

    return this._http.get<ApiResponsePageable<UserInfo>>(`${environment.api}/user/search?${paginate}${filterParams}`);
  }
  public getUser(): Observable<ApiResponse<UserInfo>> {
    return this._http.get<ApiResponse<UserInfo>>(`${environment.api}/user/me`);
  }

  public userById(id: string): Observable<ApiResponse<UserInfo>> {
    return this._http.get<ApiResponse<UserInfo>>(`${environment.api}/user/${id}`);
  }

  public postUser(token: string, user: FormData): Observable<ApiResponse<UserInfo>> {
    return this._http.post<ApiResponse<UserInfo>>(`${environment.api}/open/user/${token}`, user);
  }

  public patchUser(id: string, user: FormData): Observable<ApiResponse<UserInfo>> {
    return this._http.post<ApiResponse<UserInfo>>(`${environment.api}/user/${id}?_method=PATCH`, user);
  }

  public updateStatus(id: string, newStatus: string | UserStatus): Observable<ApiResponse<UserInfo>> {
    return this._http.patch<ApiResponse<UserInfo>>(`${environment.api}/user/status/${id}`, {status: newStatus});
  }

  public deleteUser(id: string): Observable<DeleteApiResponse> {
    return this._http.delete<DeleteApiResponse>(`${environment.api}/user/${id}`);
  }


  public inviteUser(email: string, cellphone: number): Observable<ApiResponse<string>> {
    return this._http.post<ApiResponse<string>>(`${environment.api}/user/invite`, {email: email, cellphone: cellphone});
  }

  public recoverPassword(email: string): Observable<ApiResponse<string>> {
    return this._http.post<ApiResponse<string>>(`${environment.api}/open/user/recover-password/`, {email: email});
  }

  public resetPassword(token: string, password : string): Observable<ApiResponse<string>> {
    return this._http.post<ApiResponse<string>>(`${environment.api}/open/user/reset-password/${token}`, {password: password});
  }

  public validateCode(code : string) : Observable<ApiResponse<string>> {
    return this._http.post<ApiResponse<string>>(`${environment.api}/validate-code?_method=GET`, {code})
  }

  public validateToken(token : string) : Observable<ApiResponse<string>> {
    return this._http.post<ApiResponse<string>>(`${environment.api}/open/user/check-token`, {token: token})
  }

}
