import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { Router, RouterModule, Routes } from '@angular/router';
import { environment } from '@env/environment';
import { HasSessionGuard } from './guards/has-session.guard';
import { SessionService } from '@store/session/session.service';


const routes: Routes = [
  {
    path: '',
    loadChildren: () => import('./views/session/session.module').then(m => m.SessionModule),
  },
  {
    path: 'painel',
    loadChildren: () => import('./views/private/private.module').then(m => m.PrivateModule),
    canActivate: [HasSessionGuard] 
  },
  // {
	// 	path: '**',
	// 	redirectTo: environment.home
	// }
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { anchorScrolling: 'enabled'}),
    BrowserModule,
  ],
  exports: [RouterModule],
  providers: [
    {
      provide: 'AuthGuard',
      useFactory: HasSessionGuard,
      deps: [SessionService, Router]
    }
  ]
})
export class AppRoutingModule { }
