import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { environment } from "@env/environment.dev";
import {
  ApiResponse,
  ApiResponsePageable,
  DeleteApiResponse,
  PageControl,
} from "@model/application";
import { Notification } from "@model/notification";
import { Utils } from "@shared/utils";
import { getMessaging, onMessage } from "firebase/messaging";
import { Observable } from "rxjs";

@Injectable({
  providedIn: "root",
})
export class NotificationService {
  private messaging;

  constructor(
    private readonly _http: HttpClient
  ) {
    this.messaging = getMessaging();
  }

  public getNotifications(pageControl: PageControl, filters?): Observable<ApiResponsePageable<Notification>> {
    const paginate = Utils.mountPageControl(pageControl);
    const filterParams = Utils.mountPageControl(filters);

    return this._http.get<ApiResponsePageable<Notification>>(`${environment.api}/notification/search?${paginate}${filterParams}`);
  }

  public getNotification(id: string): Observable<ApiResponse<Notification>> {
    return this._http.get<ApiResponse<Notification>>(`${environment.api}/notification/${id}`);
  }

  public postNotification(notification: Notification): Observable<ApiResponse<Notification>> {
    return this._http.post<ApiResponse<Notification>>(`${environment.api}/notification`, notification);
  }

  public patchNotification(id: string, notification: Notification): Observable<ApiResponse<Notification>> {
    return this._http.patch<ApiResponse<Notification>>(`${environment.api}/notification/${id}`, notification);
  }
  
  public deleteNotification(id: string): Observable<DeleteApiResponse> {
    return this._http.delete<DeleteApiResponse>(`${environment.api}/notification/${id}`);
  }

  listenForMessages() {
    onMessage(this.messaging, (payload) => {
      alert(`${payload.notification?.title} - ${payload.notification?.body}`);
    });
  }
}
