import { Component, HostListener, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { environment } from '@env/environment';
import { FcmTokenService } from '@services/fcm-token.service';
import { NotificationService } from '@services/notification.service';
import { SidebarService } from '@services/sidebar.service';

@Component({
  selector: 'app-root',
  template: '<router-outlet></router-outlet>',
})
export class AppComponent implements OnInit {

	constructor(
    private readonly titleService: Title,
    public sidebarService: SidebarService,
    
    private notificationService: NotificationService,
    private fcmTokenService: FcmTokenService
    ) {}

	ngOnInit(): void {
    this.titleService.setTitle(environment.appName);

    this.notificationService.listenForMessages();

    this.fcmTokenService.getFcmToken()
      .then(
        token => {
        localStorage.setItem('fcmToken', token);
        },
      )
      .catch(error => console.error("Erro ao recuperar o FCM Token:", error));
	}
  
  @HostListener('document:click', ['$event'])
  onDocumentClick(event: MouseEvent): void {
    if (this.sidebarService.active.value && window.matchMedia('(max-width: 1199px)').matches) {
      this.sidebarService.active.next(false);
    }
  }
}
