import { getMessaging, getToken } from "firebase/messaging";
import { Injectable } from "@angular/core";
import { environment } from "@env/environment.dev";

@Injectable({
  providedIn: "root",
})
export class FcmTokenService {
  private messaging;
  constructor() {
    this.messaging = getMessaging();
  }

  getFcmToken(): Promise<string> {
    

    return new Promise((resolve, reject) => {
      Notification.requestPermission()
        .then((permission) => {
          if (permission === "granted") {
            getToken(this.messaging, {
              vapidKey: environment.firebaseConfig.vapidKey,
            })
              .then((currentToken) => {
                if (currentToken) {
                  resolve(currentToken);
                } else {
                  console.log("Token não recebido");
                }
              })
              .catch((err) => {
                console.log("Erro ao recuperar o token FCM: ", err);
                reject("Erro ao recuperar o token FCM: " + err);
              });
          } else {
            reject("Permissão de notificações não concedida.");
          }
        })
        .catch((err) => {
          console.error("Erro ao solicitar permissão de notificações:", err);
          reject("Erro ao solicitar permissão de notificações: " + err);
        });
    });
  }
}
