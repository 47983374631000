export const environment = {
    production: false,
      appName: 'Vapt Manager',
      home: '/painel',
      api: 'https://api-dev.vapt.mobi/api',
      // api: 'https://a724-187-19-130-126.ngrok-free.app/api',
      // api: 'http://127.0.0.1:8000/api',
      url: '',
  
      passwordRules: {
          minlength: 8,
          minLowercaseCharacterRule: 1,
          minUppercaseCharacterRule: 1,
          minDigitCharacterRule: 1,
          minSpecialCharacterRule: 1
      },
  };
  