/// <reference types="@angular/localize" />

import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment.dev';
import { initializeApp } from 'firebase/app';


if (environment.production) {
  enableProdMode();
}

// Inicializando o Firebase
initializeApp(environment.firebaseConfig); // Inicia o Firebase com a configuração do environmentd

if('serviceWorker' in navigator) {
  navigator.serviceWorker.register('/firebase-messaging-sw.js')
  .then((registration) => {
    console.log('Service Worker registrado com sucesso:', registration);
  })
  .catch((err) => {
    console.error('Falha ao registrar o Service Worker;', err);
  })
}

platformBrowserDynamic().bootstrapModule(AppModule)
  .catch(err => console.error(err));
