import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})

export class SidebarService {
	active = new BehaviorSubject<boolean>(true);
	minSidebar = false;
}
